$(function() {
	$('img').removeAttr('width').removeAttr('height');

	var featuredCarousels = [];
	$('#featured-carousels .gallery').each(function(index, element) {

		// autoDelay = (Math.floor(Math.random() * 7) + 1)*250;
		var autoDelay;

		switch ( $(this).attr('id') ) {
			case 'gallery-1' :
				autoDelay = 0;
				break;
			case 'gallery-2' : 
				autoDelay = 500;
				break;
			case 'gallery-3' : 
				autoDelay = 1000;
				break;
			default :
				autoDelay = 0;
		}
		
		featuredCarousels[index] = $(element).bxSlider({
			mode: 'fade',
			pager: false,
			controls: false,
			speed: 2000,
			pause: 4000,
			autoDelay: autoDelay,
			auto: true
		});
		
	});

	// Gallery page modification
	var galleries = $('.gallery');

	if ( galleries.length ) {
		
		$('a', galleries).colorbox({
			rel: 'gallery', 
			maxWidth: '1050px', 
			maxHeight: '80%', 
			minWidth: '50%', 
			minHeight: '50%', 
			fadeOut: 0
		});

	}

	// Request callback
	var request_callback_btn = $('#request-callback');

	if ( request_callback_btn.length ) {

		request_callback_btn.colorbox({
			maxWidth: '90%', 
			maxHeight: '90%', 
			minWidth: '50%', 
			minHeight: '50%', 
			fadeOut: 0,
			opacity: 0.75,
			innerWidth: 300,
			innerHeight: 450,
			iframe: true
		});
		$('.wpcf7-submit').on('click', function() {
			alert('okay');
		});

	}

	// Prevent window from scrolling
	$(document).bind('cbox_open', function () {
		$('html').css('overflow', 'hidden');
	}).bind('cbox_closed', function () {
		$('html').css('overflow', 'auto');
	});

	// Packages 
	var $packages_page = $('.page-packages');

	if ($packages_page.length) {
		var $content_modules = $('.content-module', $packages_page);
		var $tabs = $('.tab', $packages_page);
		var selected_class = 'is-selected';

		$content_modules.hide();
		$tabs.on('click', function() {
			$('html, body').animate({scrollTop: $(this).offset().top});
			$tabs.removeClass(selected_class);
			$content_modules.removeClass(selected_class).hide();
			$(this).addClass(selected_class);
			$('.content-module').eq($tabs.index(this)).toggle();
		});
	}
});